import React from "react";

export default function SitecoreXMCLogo({ className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 374.4000041485 354.1999804974"
			className={className}
			stroke="currentColor"
			fill="currentColor"
		>
			<path d="M8.6002004147,299.7999942303l51.3999986649,51.3999938965c1.9999980927,2.0000076294,4.6000003815,2.9999923706,6.9999980927,2.9999923706s5.2000045776-.9999847412,6.9999980927-2.9999923706c31.1999988556-31.1999893188,71.9998073578-46.8000030518,112.7998065948-46.8000030518s81.5999984741,15.6000137329,112.799987793,46.8000030518c2.0000076294,2.0000076294,4.5999908447,2.9999923706,7.0000076294,2.9999923706s5.2000045776-.9999847412,7.0000076294-2.9999923706l51.3999938965-51.3999938965c7.0000076294-7.0000076294,7.0000076294-18.6000061035,0-25.6000137329l-121.8000030518-121.7999839783c-15.5999946594-15.5998039246-36.0000038147-23.3997917175-56.5999984741-23.3997917175s-41.0000038147,7.799987793-56.5998077393,23.3997917175L8.6002004147,274.1999804974c-7.000002861,7.0000076294-7.000002861,18.6000061035,0,25.6000137329Z" />
			<path d="M84.5999991894,155.799998045l24.8000049591-24.7999954224c20.7999992371-20.7999992371,48.4000015259-32.2000026703,77.799987793-32.2000026703,29.4000053406,0,57.0000076294,11.4000034332,77.8000068665,32.2000026703l24.7999954224,24.7999954224,81.5999984741-81.5999984741c4.0000152588-3.9999961853,4.0000152588-10.2000045776,0-14.2000007629L314.0000140667,3.0000007153c-4.0000152588-4.0000009537-10.2000045776-4.0000009537-14.2000198364,0-62.1999931335,62.2000026703-163.1999969482,62.2000026703-225.5999946594,0-3.9999961853-4.0000009537-10.2000045776-4.0000009537-14.2000007629,0L3.0000007153,59.9999988079c-4.0000009537,3.9999961853-4.0000009537,10.2000045776,0,14.2000007629l81.5999984741,81.5999984741Z" />
		</svg>
	);
}
