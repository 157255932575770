import React from "react";

export default function SitecoreCDPLogo({ className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 399.8229600368 335.6583032166"
			className={className}
			stroke="currentColor"
			fill="currentColor"
		>
			<path d="M242.0229531704,217.9750441242c-4.3999862671-4.3999862671-3.7999916077-11.7999839783,1.5999984741-15.1999855042,26.40001297-17.200012207,42.1999931335-49.8000144958,34.2000007629-85.2000045776-6.3999938965-29.1996002197-30-52.9996013641-58.9999961853-59.5995998383-52.3999977112-12.0000076294-98.9998054504,27.3999881744-98.9998054504,77.7995967865,0,27.9999923706,14.4000053406,52.6000022888,36.40001297,66.8000030518,5.1997947693,3.4000015259,5.9997940063,10.7999992371,1.5999984741,15.2000045776l-13.0000114441,12.9999923706c-32.799987793-18.9999961853-54.7999954224-54.4000053406-54.7999954224-95.2000045776,0-26.3995933533,9.4000053406-50.5995941162,24.8000049591-69.3995952606,3.4000015259-3.9999961853,3.4000015259-10-.3999996185-13.5999965668L64.8231650768,2.9754622508c-3.9999961853-4.000005722-10.7999992371-4.000005722-14.6000003815,.1999950409C12.2231615959,46.1754606892-7.7768360199,105.5752657581,2.8231657921,169.5750425983c13.5999965668,82.8000068665,80.1999914646,149.4000053406,162.9997980595,163.1999969482,53.7999916077,8.9999771118,103.9999961853-3.6000061035,144.4000053406-30.4000091553,2.8058624268-2.542552948,6.8419647217-8.895778656,1.3999938965-15.4000091553,0,0-69.6000099182-68.9999961853-69.6000099182-68.9999771118Z" />
			<path d="M285.4229737697,52.575664394c-3.8000106812,3.7999916077-3.8000106812,9.6000003815-.4000091553,13.5999965668,15.4000091553,18.9999961853,24.7999954224,43.1999969482,24.7999954224,69.399394989,0,29.8000049591-12.4275970459,57.7448368073-31.6275978088,77.5448322296l56.8276023865,55.2551651001c4.8958969116,4.5091247559,10.7999801636,4.0000152588,14.5999908447-.1999664307,31.1999893188-35.2000236511,50.2000045776-81.6000175476,50.2000045776-132.4000167847,0-50.799407959-19.0000152588-97.1994018555-50.2000045776-132.3993968964-3.8000106812-4.2000007629-10.6000137329-4.2000007629-14.5999908447-.2000045776l-49.5999908447,49.6000003815v-.2000045776Z" />
		</svg>
	);
}
